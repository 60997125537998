import {timeFormat,numberFormat} from "@custom";

export function tableColumn(_this){
    return  [
        {
            prop: "createDate",
            label: "发送时间",
            width: 200,
            render: (h, {row}) => {
                const operateTime = ! row.createDate ? "" : timeFormat(new Date(row.createDate), 'yyyy-MM-dd HH:mm:ss');
                return h("span", operateTime);
            }
        },
        {
            label: '操作人',
            prop: 'createByUser',
            width: 120,
        },
        
        {
            // prop: "apartmentName",
            label: "房源名称",
            width: 200,
            render: (h, {row}) => {
                const {apartmentName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            _this.$router.push({
                                name: "house-resource",
                                query: {data: {apartmerntName: apartmentName}}
                            });
                        }
                    }
                }, apartmentName);}
        },
        {
            label: "姓名",
            width: 100,
            render: (h, {row}) => {
                const {leasorName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            let leasorName = '唐晓娴'
                            _this.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                        }
                    }
                }, leasorName);
            }
        },
        {
            prop: "leasorPhone",
            label: "联系方式",
            width: 150,
        },
        {
            prop: "smsType",
            label: "短信类型",
            width: 100,
            render: (h, param) => {
                const {smsType} = param.row;
                if(smsType === 0) return '验证码'
                if(smsType === 1) return '催缴短信'
                if(smsType === 2) return '密码短信'
                if(smsType === 3) return '其他'
                if(smsType === 4) return '小程序低电量'
            }
        },
        {
            // prop: "smsContent",
            label: "发送内容",
            // showOverflowTooltip: true,
            render: (h, param) => {
                const {smsContent} = param.row;
                // return smsContent
                //  let smsContent = '明月几时有？把酒问青天。不知天上宫阙，今夕是何年。我欲乘风归去，又恐琼楼玉宇，高处不胜寒。起舞弄清影，何似在人间,明月几时有？把酒问青天。不知天上宫阙，今夕是何年。我欲乘风归去，又恐琼楼玉宇，高处不胜寒。起舞弄清影，何似在人间,明月几时有？把酒问青天。不知天上宫阙，今夕是何年。我欲乘风归去，又恐琼楼玉宇，高处不胜寒。起舞弄清影，何似在人间' +
                //     '把酒问青天。不知天上宫阙，今夕是何年。我欲乘风归去，又恐琼楼玉宇，高处不胜寒。起舞弄清影，何似在人间,明月几时有？把酒问青天。不知天上宫阙，今夕是何年。我欲乘风归去，又恐琼楼玉宇，高处不胜寒。起舞弄清影，何似在人间'
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    // on: {
                    //     click() {
                    //         let leasorName = '唐晓娴'
                    //         _this.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                    //     }
                    // }
                }, smsContent);
                // let myoperateModuledom = <div style="height:58px;overflow-y: auto;width:400px;">{myoperateModule}</div>
                // return (myoperateModuledom)
                // return h("span", {
                //     style: {
                //
                //     }
                // }, smsContent);
            }
        }
    ];
}
export function ftableColumn(_this) {
    return [
        {
            type: "index",
            label: "序号",
            width: 100
        },
        {
            // 2023-9--22-11-10-陈守亮再次优化超链接跳转
            prop: "contractNum",
            label: "合同编号",
            width: 150,
            render: (h, {row}) => {
                const {electContractType, uuid} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            if (electContractType === 3) {
                                _this.$alert('线下签约', '提示', {confirmButtonText: '确定',});
                                return;
                            }

                            _this.lookDianZiHeTong(uuid, electContractType);
                        }
                    }
                }, row.contractNum);
            },
        },
        {
            prop: "finished",
            label: "合同状态",
            width: 100,
            render: (h, {row}) => {
                let {statusCode: code, statusCodeName: codeName} = row;
                let type = "";
                switch (code) {
                    case 500701:
                        type = "success";
                        break;
                    case 500702:
                        type = "info";
                        break;
                    case 500703:
                        type = "warning";
                        break;
                    case 500704:
                        type = "";
                        break;
                    case 500705:
                        type = "danger";
                        break;
                    case 500706:
                        type = "success";
                        break;
                }
                if (code === 500709) codeName = "待完善";
                return h("el-tag", {props: {type: type, size: "small"}}, codeName);
            }
        },
        {
            prop: "natureCode",
            label: "合同性质",
            width: 100,
            render: (h, {row}) => {
                const {natureCode} = row;
                return h("span", natureCode === 500601 ? "企业合同" : natureCode === 500602 ? "个人合同" : "暂无数据");
            }
        },
        {
            label: "租户名称",
            width: 100,
            render: (h, {row}) => {
                const {leasorName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            _this.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                        }
                    }
                }, leasorName);
            }
        },
        {
            prop: 'lesseeIdCard',
            label: '身份证号',
            width: 200,
        },
        {
            prop: "phone",
            label: "租户手机号",
            width: 100
        },
        {
            prop: "district",
            label: "区域",
            width: 150
        },
        {
            label: "房源名称",
            width: 200,
            // showOverflowTooltip: true,
            render: (h, {row}) => {
                const {apartmentName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            _this.$router.push({
                                name: "house-resource",
                                query: {data: {apartmerntName: apartmentName}}
                            });
                        }
                    }
                }, apartmentName);
            }
        },

        // {
        //     prop:'payDepoAmount',
        //     label:'房租金额(元)'
        // },

        {
            prop: "amount",
            label: "房租金额(元)",
            width: 100,
            render: (h, {row}) => {
                const {amount} = row;
                return h("span", amount ? numberFormat(amount / 100) : "暂无数据");
            }
        },
        {
            prop: 'payDepoAmount',
            label: '押金金额(元)',
            width: 100,
            render: (h, {row}) => {
                const {payDepoAmount} = row;
                return h("span", payDepoAmount ? numberFormat(payDepoAmount / 100) : "暂无数据");
            }
        },
        {
            prop: "startDate",
            label: "合同开始时间",
            width: 150,
            sortable: true
        },
        {
            prop: "endDate",
            label: "合同结束时间",
            width: 150,
            sortable: true
        },
        {
            prop: "terminatedDate",
            label: "合同终止时间",
            width: 150,
            render: (h, {row}) => {
                const {terminatedDate} = row;
                if (terminatedDate) return h("span", timeFormat(new Date(terminatedDate)));
            },
            sortable: true
        },
        // 张晓瑜新增
        {
            prop: "signDate",
            label: "签约时间",
            width: 150,
        },
        // {
        //     prop: "checkOutDate",
        //     label: "退租时间",
        //     width: 150,
        // },
        {
            prop: "area",
            label: "建筑面积（㎡）",
            width: 150,
        },
        {
            prop: "manager",
            label: "经办人",
        },
        {
            prop: "keeper",
            label: "管理人",
        },
        {
            prop: "haveMoFangContract",
            label: "电子合同",
            width: 150,
            render: (h, {row}) => {
                const {uuid, electContractType} = row;
                if (electContractType !== 3) {
                    return h("el-link", {
                        props: {type: "primary", underline: false}, on: {
                            click() {
                                _this.lookDianZiHeTong(uuid, electContractType);
                            }
                        }
                    }, "查看");
                } else return h("span", "见房源详情");
            },
        },
        {
            prop: "comment",
            label: "备注",
            showOverflowTooltip: true
        },
        /*{
            prop: "billingStartDate",
            label: "账单时间",
            width: 150
        },
        {
            prop: "signedDate",
            label: "签约时间",
            width: 150
        },
        {
            prop: "terminatedDate",
            label: "合同终止时间",
            width: 150
        },
        {
            prop: "signed",
            label: "签名",
            width: 100
        },
        {
            prop: "finished",
            label: "合同是否结束",
            width: 100
        },
        {
            prop: "monthlyRent",
            label: "月租金",
            width: 100
        },
        {
            prop: "depositAmount",
            label: "显示金额",
            width: 100
        },
        {
            prop: "rentalAmount",
            label: "租金",
            width: 100
        },
        {
            prop: "refund",
            label: "退款状态",
            width: 100
        },
        {
            prop: "refundedAmount",
            label: "退款金额",
            width: 100
        },
        {
            prop: "comment",
            label: "备注"
        },
        {
            prop: "operatorTime",
            label: "操作时间",
            width: 150
        },
        {
            prop: "createTime",
            label: "创建时间",
            width: 150
        },*/
        /*{
            label: "操作",
            render: (h, {row}) => {

                return [
                    /!*h("el-button",{
                        props:{
                            type: "success",
                            size: "mini"
                        },
                        on:{
                            click: ()=>{ _this.xuzu(row); }
                        },
                        style: {
                            marginRight:"5px"
                        }
                    }, "续签"),*!/
                    h("el-button", {
                        props: {
                            type: "danger",
                            size: "mini"
                        },
                        on: {
                            click: () => {
                                _this.openCheckOut(row);
                            }
                        },
                        style: {
                            marginRight: "5px"
                        }
                    }, "退房"),
                    /!*h("el-button",{
                        props:{
                            type: "danger",
                            size: "mini"
                        },
                        on:{
                            click: ()=>{ }
                        },
                        style: {
                            marginRight:"5px"
                        }
                    }, "终止"),*!/
                ]
            }
        },*/
    ];
}
